import { useParams } from "react-router-dom";

import type { OptionalPathParams, ParameterlessRoute, ParameterRoute, Path, PathParams, Route, TypedObject } from "src/types";

// public
const VERSION = "version" as const;

const SIGN_IN = "sign-in" as const;
const SIGN_UP = "sign-up" as const;
const VERIFY = "verify" as const;
const EMAIL_VERIFIED = "email-verified" as const;
const VERIFY_SECRET = "secret" as const;
const FORGOT_PASSWORD = "forgot-password" as const;
const NEW_PASSWORD = "new-password" as const;

// private
const VERIFICATION = "verification" as const;
const VERIFICATION_TYPE = "verificationType" as const;

const DASHBOARD = "dashboard" as const;

const EARNINGS = "earnings" as const;

const ORDERS = "orders" as const;
const ORDER_DOCUMENT_ID = "orderDocumentId" as const;

const PROJECTS = "projects" as const;
const PROJECT_SLUG = "projectSlug" as const;

const VOTING = "voting" as const;
const SETTINGS = "settings" as const;

const ARTICLE = "article" as const;
const ARTICLE_SLUG = "articleSlug" as const;
const NEWS = "news" as const;
const INVESTOR_CLUB = "investor-club" as const;
const HOW_TO = "how-to" as const;

/**
 * Generate path description. Just add ":" before parameters names and use them in fillPathParamsFunc.
 * @param filPathParamsFunc - path for fill parameters could be used, just add ":" before route parameters names
 * @param params - parameters of the route.
 */
const generatePath = <P extends PathParams>(filPathParamsFunc: (params: P) => Path, params: P): Path =>
    filPathParamsFunc(Object.entries(params).reduce((result, [key, param]) => Object.assign(result, { [key]: `:${param}` }), {} as P));

/**
 * Creates simple route without path params.
 * @param path Route path.
 */
const createSimpleRoute = (path: Path): ParameterlessRoute => ({
    path,
    parameterless: true,
});

type VerifySecretPathParams = PathParams<typeof VERIFY_SECRET>;
const fillVerifySecretPathParams = ({ secret }: VerifySecretPathParams) => `/${VERIFY}/${secret}`;

type ProjectDetailPathParams = PathParams<typeof PROJECT_SLUG>;
const fillProjectDetailPathParams = ({ projectSlug }: ProjectDetailPathParams) => `/${PROJECTS}/${projectSlug}`;

type ProjectOrderPathParams = PathParams<typeof PROJECT_SLUG>;
const fillProjectOrderPathParams = ({ projectSlug }: ProjectOrderPathParams) => `/${PROJECTS}/${projectSlug}/order`;

type OrderDetailPathParams = PathParams<typeof ORDER_DOCUMENT_ID>;
const fillOrderDetailPathParams = ({ orderDocumentId }: OrderDetailPathParams) => `/${ORDERS}/${orderDocumentId}`;

type OrderPayPathParams = PathParams<typeof ORDER_DOCUMENT_ID>;
const fillOrderPayPathParams = ({ orderDocumentId }: OrderPayPathParams) => `/${ORDERS}/${orderDocumentId}/payment`;

type VerificationPathParams = OptionalPathParams<typeof VERIFICATION_TYPE>;
const fillVerificationPathParams = ({ verificationType }: VerificationPathParams) =>
    verificationType ? `/${VERIFICATION}/${verificationType}` : `/${VERIFICATION}`;

type ArticleDetailPathParams = PathParams<typeof ARTICLE_SLUG>;
const fillArticleDetailPathParams = ({ articleSlug }: ArticleDetailPathParams) => `/${ARTICLE}/${articleSlug}`;

export const Routes = {
    version: createSimpleRoute(`/${VERSION}`),

    signIn: createSimpleRoute(`/${SIGN_IN}`),
    signUp: createSimpleRoute(`/${SIGN_UP}`),
    emailVerified: createSimpleRoute(`/${EMAIL_VERIFIED}`),
    googleCallback: createSimpleRoute("/callback/google"),

    verify: {
        path: generatePath(fillVerifySecretPathParams, { [VERIFY_SECRET]: VERIFY_SECRET }),
        fillPathParams: fillVerifySecretPathParams,
        useParams: () => useParams<VerifySecretPathParams>(),
    } as const satisfies ParameterRoute<VerifySecretPathParams>,

    forgotPassword: createSimpleRoute(`/${FORGOT_PASSWORD}`),
    newPassword: createSimpleRoute(`/${NEW_PASSWORD}`),

    verifications: {
        path: generatePath(fillVerificationPathParams, { [VERIFICATION_TYPE]: `${VERIFICATION_TYPE}?` }),
        fillPathParams: fillVerificationPathParams,
        useParams: () => useParams<VerificationPathParams>(),
    } as const satisfies ParameterRoute<VerificationPathParams>,

    dashboard: createSimpleRoute(`/${DASHBOARD}`),
    earnings: createSimpleRoute(`/${EARNINGS}`),
    voting: createSimpleRoute(`/${VOTING}`),
    settings: createSimpleRoute(`/${SETTINGS}`),

    orders: createSimpleRoute(`/${ORDERS}`),
    orderDetail: {
        path: generatePath(fillOrderDetailPathParams, { [ORDER_DOCUMENT_ID]: ORDER_DOCUMENT_ID }),
        fillPathParams: fillOrderDetailPathParams,
        useParams: () => useParams<OrderDetailPathParams>(),
    } as const satisfies ParameterRoute<OrderDetailPathParams>,
    orderPay: {
        path: generatePath(fillOrderPayPathParams, { [ORDER_DOCUMENT_ID]: ORDER_DOCUMENT_ID }),
        fillPathParams: fillOrderPayPathParams,
        useParams: () => useParams<OrderPayPathParams>(),
    } as const satisfies ParameterRoute<OrderPayPathParams>,

    projects: createSimpleRoute(`/${PROJECTS}`),
    projectDetail: {
        path: generatePath(fillProjectDetailPathParams, { [PROJECT_SLUG]: PROJECT_SLUG }),
        fillPathParams: fillProjectDetailPathParams,
        useParams: () => useParams<ProjectDetailPathParams>(),
    } as const satisfies ParameterRoute<ProjectDetailPathParams>,
    projectOrder: {
        path: generatePath(fillProjectOrderPathParams, { [PROJECT_SLUG]: PROJECT_SLUG }),
        fillPathParams: fillProjectOrderPathParams,
        useParams: () => useParams<ProjectOrderPathParams>(),
    } as const satisfies ParameterRoute<ProjectOrderPathParams>,

    news: createSimpleRoute(`/${NEWS}`),
    investorClub: createSimpleRoute(`/${INVESTOR_CLUB}`),
    howTo: createSimpleRoute(`/${HOW_TO}`),
    articleDetail: {
        path: generatePath(fillArticleDetailPathParams, { [ARTICLE_SLUG]: ARTICLE_SLUG }),
        fillPathParams: fillArticleDetailPathParams,
        useParams: () => useParams<ArticleDetailPathParams>(),
    } as const satisfies ParameterRoute<ArticleDetailPathParams>,
} as const satisfies TypedObject<Route>;
