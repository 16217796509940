import type { FC } from "react";

import { RightArrowIcon } from "src/icons";
import { Routes } from "src/routes";
import { Link } from "./Link";

type ProjectTableCellProps = Readonly<{
    projectSlug?: string;
    projectTitle?: string;
    thumbnailUrl?: string;
}>;

export const ProjectLink: FC<ProjectTableCellProps> = ({ projectSlug, projectTitle, thumbnailUrl }) => (
    <Link
        href={projectSlug ? Routes.projectDetail.fillPathParams({ projectSlug }) : ""}
        disabled={!projectSlug}
        className="flex gap-4 font-inter font-semibold text-brand hover:text-brand hover:no-underline focus:text-brand"
    >
        {thumbnailUrl && <img className="size-12 rounded" src={thumbnailUrl} alt={projectTitle} />}
        <div className="flex min-w-0 items-center">
            <span className="truncate font-medium">{projectTitle ?? PROJECT_TITLE_PLACEHOLDER}</span>
            <RightArrowIcon className="ml-2 size-5 shrink-0 fill-brand-primary" />
        </div>
    </Link>
);

const PROJECT_TITLE_PLACEHOLDER = "...";
