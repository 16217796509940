import type { FC } from "react";
import { BannerPageEnum } from "types/cms";

import { PageLayout } from "src/components";
import { useEarningsListQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { EarningsExportButton, EarningsTable } from "./components";

export const EarningsPage: FC = () => {
    const { t } = useTranslation();

    const { data: earnings, isError, isLoading } = useEarningsListQuery();

    return (
        <PageLayout
            loading={isLoading}
            banner={BannerPageEnum.Earnings}
            rightCornerComponent={earnings?.data && earnings.data.length > 0 && <EarningsExportButton />}
            title={t("earnings.title")}
        >
            {isError ? <div>{t("earnings.error")}</div> : <EarningsTable earnings={earnings} />}
        </PageLayout>
    );
};
