import type { FC, SVGProps } from "react";

import { Routes } from "src/app/routes";
import {
    BarChartIcon,
    BriefcaseIcon,
    FileListIcon,
    HomeIcon,
    MoneyBagIcon,
    NewspaperIcon,
    PieChartIcon,
    QuestionIcon,
    SettingsIcon,
} from "src/icons";
import type { LocKey } from "src/translations";
import type { ParameterlessRoute } from "src/types";

export type MenuItem = Readonly<{
    route: ParameterlessRoute;
    Icon: FC<SVGProps<SVGSVGElement>>;
    titleKey: LocKey;
}>;

export const menuItems: ReadonlyArray<MenuItem> = [
    {
        Icon: PieChartIcon,
        titleKey: "navigation.dashboard",
        route: Routes.dashboard,
    },
    {
        Icon: MoneyBagIcon,
        titleKey: "navigation.earnings",
        route: Routes.earnings,
    },
    {
        Icon: FileListIcon,
        titleKey: "navigation.orders",
        route: Routes.orders,
    },
    {
        Icon: HomeIcon,
        titleKey: "navigation.projects",
        route: Routes.projects,
    },
    {
        Icon: BarChartIcon,
        titleKey: "navigation.voting",
        route: Routes.voting,
    },
    {
        Icon: NewspaperIcon,
        titleKey: "navigation.news",
        route: Routes.news,
    },
    {
        Icon: BriefcaseIcon,
        titleKey: "navigation.investorClub",
        route: Routes.investorClub,
    },
    {
        Icon: QuestionIcon,
        titleKey: "navigation.howTo",
        route: Routes.howTo,
    },
    {
        Icon: SettingsIcon,
        titleKey: "navigation.settings",
        route: Routes.settings,
    },
] as const;
