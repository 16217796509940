import type { AppState, UserDividendsPayoutsResponse } from "src/types";
import { HttpMethod } from "./constants";
import { rootApi } from "./rootApi";
import { EARNINGS_TAG } from "./tags";
import { getCoreUrl } from "./utils";

export const earningsApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        earningsList: build.query<UserDividendsPayoutsResponse, void>({
            queryFn: async (_, { getState }, _extra, fetchWithBaseQuery) => {
                // PERF: This is a workaround to avoid fetching earnings history if the last payout is the same as the last cached one.
                const refetchEarnings = async () =>
                    (await fetchWithBaseQuery(getCoreUrl("/user/dividends/history"))) as { data: UserDividendsPayoutsResponse };

                const cachedData = (getState() as AppState).api.queries["earningsList(undefined)"]?.data as
                    | UserDividendsPayoutsResponse
                    | undefined;

                if (!cachedData) return refetchEarnings();

                const lastPayoutResult = await fetchWithBaseQuery(getCoreUrl("/user/dividends/last-payout"));
                if (lastPayoutResult.error) return { error: lastPayoutResult.error };
                const lastPayout = lastPayoutResult.data as { importId: string };

                if (cachedData.lastImportId === lastPayout.importId) {
                    return { data: cachedData };
                }

                return refetchEarnings();
            },
            providesTags: [EARNINGS_TAG],
        }),
        exportEarnings: build.query<Blob, number>({
            query: (year) => ({
                url: getCoreUrl(`/user/dividends/tax-overview/${year}`),
                method: HttpMethod.GET,
                responseHandler: async (response) => response.blob(),
            }),
        }),
    }),
});

export const { useEarningsListQuery, useLazyExportEarningsQuery } = earningsApi;
