import { type FC, useState } from "react";

import { ButtonWithIcon, PopupWindow, Select, SelectItem, Spinner } from "src/components";
import { DownloadIcon } from "src/icons";
import { useLazyExportEarningsQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logger } from "../logger";

export const EarningsExportButton: FC = () => {
    const { t } = useTranslation();
    const [triggerExport] = useLazyExportEarningsQuery();
    const [isLoading, setIsLoading] = useState(false);
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState(currentYear);

    const handleExport = async () => {
        try {
            setIsLoading(true);
            const response = await triggerExport(year).unwrap();
            const blob = new Blob([response], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `investbay-earnings-${year}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            logger.error("Failed to export earnings", error);
            await PopupWindow.fire({
                title: t("earnings.export.error.title"),
                text: t("earnings.export.error.text"),
                icon: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="inline-flex items-center">
            <ButtonWithIcon icon={isLoading ? <Spinner /> : <DownloadIcon className="fill-light" />} onClick={handleExport}>
                {t("earnings.export.button")}
            </ButtonWithIcon>
            <Select
                className="h-full"
                triggerClassName="h-12 rounded-l-none"
                value={year.toString()}
                onChange={(value) => setYear(Number(value))}
            >
                {getYearOptions().map((option) => (
                    <SelectItem className="h-12" key={option} value={option.toString()}>
                        {option}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
};

const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const targetYear = 2024;
    const yearCount = targetYear - currentYear + 1;
    return Array.from({ length: yearCount }, (_, i) => targetYear - i);
};
