import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { ProjectLink } from "src/components";
import { useTranslation } from "src/translations";
import { convertPriceInCentsToPrice } from "src/utils";
import type { DashboardProjectsItemProps } from "./types";

export const DashboardProjectsMobileItem: FC<DashboardProjectsItemProps> = ({ project, currency }) => {
    const { t } = useTranslation();
    const { projectInfo, images, tokenCount, ownershipPercentage, currentValue, earnings, price } = project;
    const thumbnailUrl = images?.[0]?.url;

    return (
        <div className="flex min-w-0 gap-2 font-inter">
            {thumbnailUrl && <img className="size-40 rounded sm:size-44" src={thumbnailUrl} alt={projectInfo?.title} />}
            <div className="flex flex-1 flex-col justify-between">
                <ProjectLink projectSlug={projectInfo?.slug} projectTitle={projectInfo?.title} />
                <div className="flex flex-col text-sm">
                    <div className="flex grow justify-between gap-1 max-sm:basis-full">
                        <span className="font-medium">{t("dashboard.projects.ownership")}:</span>
                        <span>{t("format.percent", { value: ownershipPercentage })}</span>
                    </div>
                    <div className="flex grow justify-between gap-1 max-sm:basis-full">
                        <span className="font-medium">{t("dashboard.projects.units")}:</span>
                        <span>{t("format.amount", { value: tokenCount })}</span>
                    </div>
                    <div className="flex grow justify-between gap-1 max-sm:basis-full">
                        <span className="font-medium">{t("dashboard.projects.value")}:</span>
                        <span>{t("format.currency", { value: convertPriceInCentsToPrice(currentValue), currency })}</span>
                    </div>
                    <div className="flex grow justify-between gap-1 max-sm:basis-full">
                        <span className="font-medium">{t("dashboard.projects.invested")}:</span>
                        <span>{t("format.currency", { value: convertPriceInCentsToPrice(price), currency })}</span>
                    </div>
                    <div className="flex grow justify-between gap-1 max-sm:basis-full">
                        <span className="font-medium">{t("dashboard.projects.earning")}:</span>
                        {earnings === undefined ? (
                            <Skeleton width="80px" />
                        ) : (
                            <span>{t("format.currency", { value: convertPriceInCentsToPrice(earnings), currency })}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
