import type { FC, HTMLAttributes, ReactNode } from "react";

import { cn } from "src/utils";
import { Button } from "./Button";

type ButtonWithIconProps = Readonly<{
    icon: ReactNode;
    isLoading?: boolean;
}> &
    HTMLAttributes<HTMLButtonElement>;

export const ButtonWithIcon: FC<ButtonWithIconProps> = ({ children, isLoading = false, icon, className, ...props }) => (
    <Button
        className={cn("inline-flex w-fit items-center justify-center gap-2", className)}
        type="submit"
        fullWidth
        isLoading={isLoading}
        {...props}
    >
        <span className="flex size-5 shrink-0 items-center justify-center">{icon}</span>
        <span className="truncate">{children}</span>
    </Button>
);
