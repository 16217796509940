import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { EarningsPage } from "./EarningsPage";

export const earnings = typeCheckAppModule({
    NAME,
    Container: EarningsPage,
    route: Routes.earnings,
});
