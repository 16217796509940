import { Content, Icon, Portal, Root, Trigger, Value, Viewport } from "@radix-ui/react-select";
import { forwardRef } from "react";
import type { FieldError } from "react-hook-form";

import { ArrowDownIcon, InfoIcon } from "src/icons";
import { cn } from "src/utils";
import { IconWithPopover } from "../IconWithPopover";
import { getErrorMessage } from "../utils";

export type SelectProps = Readonly<{
    children: React.ReactNode;
    value?: string;
    onChange?: (value: string) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: string | FieldError;
    additionalInfo?: string;
    contentPosition?: "popper" | "item-aligned";
    className?: string;
    triggerClassName?: string;
}>;

export const Select = forwardRef<HTMLDivElement, SelectProps>(
    (
        {
            children,
            value,
            onChange,
            label,
            placeholder,
            disabled,
            error,
            additionalInfo,
            contentPosition = "item-aligned",
            className,
            triggerClassName,
        },
        ref,
    ) => (
        <div ref={ref} className={cn("flex w-full flex-col space-y-2", className)}>
            {(label || additionalInfo) && (
                <div className="flex items-center gap-x-1">
                    {label && <label className="!text-sm text-primary">{label}</label>}
                    {additionalInfo && (
                        <IconWithPopover icon={<InfoIcon className="size-4 fill-primary" />}>{additionalInfo}</IconWithPopover>
                    )}
                </div>
            )}
            <Root value={value} onValueChange={onChange}>
                <Trigger
                    disabled={disabled}
                    className={cn(
                        "flex h-10 w-full min-w-28 items-center justify-start gap-0.5 rounded-main border border-primary px-3 py-1.5 disabled:bg-brand-disabled disabled:text-disabled",
                        error && "!border-error",
                        triggerClassName,
                    )}
                >
                    <Icon asChild>
                        <ArrowDownIcon className="w-4" />
                    </Icon>
                    <Value placeholder={placeholder} />
                </Trigger>

                <Portal>
                    <Content
                        position={contentPosition}
                        className="relative z-50 min-w-28 overflow-hidden rounded-main border border-primary bg-white shadow-e300"
                    >
                        <Viewport className="">{children}</Viewport>
                    </Content>
                </Portal>
            </Root>
            {error && <span className={cn("text-sm text-error")}>{getErrorMessage(error)}</span>}
        </div>
    ),
);

Select.displayName = "Select";
