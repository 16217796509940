import type { FC } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { CmsError, ProjectLink, TableCell } from "src/components";
import { useProjectInfoDetailByDocumentIdQuery, useProjectsInfoQuery } from "src/serverApi";

type EarningProjectCellProps = Readonly<{
    projectId: number;
}>;

export const EarningProjectCell: FC<EarningProjectCellProps> = ({ projectId }) => {
    const { t } = useTranslation();
    const {
        data: projectIdToProjectInfoDocumentIdMap,
        isLoading: isIdMapLoading,
        isError: isIdMapError,
        error: idMapError,
    } = useProjectsInfoQuery(undefined, {
        selectFromResult: ({ data, isLoading, isError, error }) => ({
            data: data?.data?.reduce<Record<number, string>>((accumulator, projectInfo) => {
                if (projectInfo?.project?.id && projectInfo.documentId) {
                    accumulator[projectInfo.project.id] = projectInfo.documentId;
                }
                return accumulator;
            }, {}),
            isLoading,
            isError,
            error,
        }),
    });

    const projectInfoDocumentId = projectIdToProjectInfoDocumentIdMap?.[projectId!] ?? "";

    const {
        data: project,
        isLoading: isProjectLoading,
        isError: isProjectError,
        error: projectError,
    } = useProjectInfoDetailByDocumentIdQuery(projectInfoDocumentId, {
        skip: !projectInfoDocumentId,
        selectFromResult: ({ data, isLoading, isError, error }) => ({
            data: { slug: data?.slug, title: data?.title },
            isLoading,
            isError,
            error,
        }),
    });

    if (isIdMapLoading || isProjectLoading) {
        return (
            <TableCell>
                <Skeleton className="h-[16px]" />
            </TableCell>
        );
    }

    if (isIdMapError || isProjectError) {
        return (
            <TableCell className="truncate">
                <CmsError error={idMapError ?? projectError} fallbackMessage={t("common.error")} />
            </TableCell>
        );
    }

    return (
        <TableCell>
            <ProjectLink projectSlug={project?.slug} projectTitle={project?.title} />
        </TableCell>
    );
};
