export const LOGGED_USER_TAG = "LOGGED_USER_TAG" as const;
export const PROJECT_TAG = "PROJECT_TAG" as const;
export const USER_PORTFOLIO_TAG = "USER_PORTFOLIO_TAG" as const;
export const FAQ_TAG = "FAQ_TAG" as const;
export const APP_CONFIG_TAG = "APP_CONFIG_TAG" as const;
export const ORDER_TAG = "ORDER_TAG" as const;
export const ARTICLE_TAG = "ARTICLE_TAG" as const;
export const MESSAGE_TAG = "MESSAGE_TAG" as const;
export const VOTING_TAG = "VOTING_TAG" as const;
export const FUNDED_REQUEST_TAG = "FUNDED_REQUEST_TAG" as const;
export const BANNER_TAG = "BANNER_TAG" as const;
export const TRANSLATED_SLUG_TAG = "TRANSLATED_SLUG_TAG" as const;
export const ENTITY_WITH_TRANSLATIONS_TAG = "ENTITY_WITH_TRANSLATIONS_TAG" as const;
export const EARNINGS_TAG = "EARNINGS_TAG" as const;
