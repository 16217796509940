import type { FC } from "react";

import { GlobeIcon, HomeSmileIcon, type IconType, MoneyBagIcon, SafeIcon } from "src/icons";
import { type LocKey, useTranslation } from "src/translations";
import type { UserPortfolio } from "src/types";
import { convertPriceInCentsToPrice } from "src/utils";

type DashboardStatsProps = Readonly<{
    currency: string;
    earnings?: number;
    portfolio?: UserPortfolio;
}>;

export const DashboardStats: FC<DashboardStatsProps> = ({ portfolio, currency, earnings }) => {
    const { t } = useTranslation();
    const { investedAmount: invested, projectCount: noProjects, countriesCount: noCountries } = portfolio ?? {};

    const statsList: ReadonlyArray<{ Icon: IconType; locKey: LocKey; value: string }> = [
        {
            Icon: SafeIcon,
            locKey: "dashboard.stats.invested",
            value: t("format.currency", { value: convertPriceInCentsToPrice(invested), currency }),
        },
        {
            Icon: MoneyBagIcon,
            locKey: "dashboard.stats.earnings",
            value: t("format.currency", { value: convertPriceInCentsToPrice(earnings), currency }),
        },
        { Icon: HomeSmileIcon, locKey: "dashboard.stats.noProjects", value: String(noProjects) },
        { Icon: GlobeIcon, locKey: "dashboard.stats.noCountries", value: String(noCountries) },
    ];

    return (
        <div className="flex flex-wrap max-xl:[&>*]:mb-4">
            {statsList.map(({ Icon, locKey, value }) => (
                <div className="flex flex-1 basis-[220px] gap-4 max-xl:basis-1/2 max-md:basis-full" key={locKey}>
                    <div className="rounded border border-subtle p-3">
                        <Icon className="size-6" />
                    </div>
                    <div className="flex flex-col font-inter">
                        <span className="text-sm">{t(locKey)}</span>
                        <span className="text-2xl">{value} </span>
                    </div>
                </div>
            ))}
        </div>
    );
};
